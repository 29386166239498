export const posts = [
  {
    id: '1',
    title: "For Heaven's Cakes!",
    publishedAt: 'November 8, 2022',
  },
  {
    id: '2',
    title: 'The shape of a cupcake',
    publishedAt: 'October 23, 2022',
  },
  {
    id: '3',
    title: 'Sweet Thang Cupcakes',
    publishedAt: 'November 12, 2022',
  },
  {
    id: '4',
    title: 'Cupcake Boulevard',
    publishedAt: 'Juli 22, 2022',
  },
  {
    id: '5',
    title: 'Red Velvet Bakery',
    publishedAt: 'June 1, 2022',
  },
]
