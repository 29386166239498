export const experiences = [
  {
    title: 'UX Strategist & Sales Funnel Designer',
    location: 'Los Angeles, United States',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi, totam at reprehenderit maxime aut beatae ad.',
  },
  {
    title: 'Freelance Graphic & Web Designer',
    location: 'Stockholm, Sweden',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi, totam at reprehenderit maxime aut beatae ad.',
  },
]
